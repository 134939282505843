import React from 'react'

export const Promix155 = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1346"
    height="687"
    viewBox="0 0 1346 687"
    {...props}
  >
    <defs>
      <pattern
        id="pattern"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 221 289"
      >
        <image
          width="221"
          height="289"
          href="../promix-155/PA01.141.13E.7602_12.png"
        />
      </pattern>
      <pattern
        id="pattern-2"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 43 51"
      >
        <image
          width="43"
          height="51"
          href="../promix-155/PA01.141.13E.7602_15.png"
        />
      </pattern>
      <pattern
        id="pattern-3"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 24 16"
      >
        <image
          width="24"
          height="16"
          href="../promix-155/PA01.141.13E.7602_13.png"
        />
      </pattern>
      <pattern
        id="pattern-4"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 93 118"
      >
        <image
          width="93"
          height="118"
          href="../promix-155/PA01.141.13E.7602_11.png"
        />
      </pattern>
      <pattern
        id="pattern-5"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 155 143"
      >
        <image
          width="155"
          height="143"
          href="../promix-155/PA01.141.13E.7602_2.png"
        />
      </pattern>
      <pattern
        id="pattern-6"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 82 105"
      >
        <image
          width="82"
          height="105"
          href="../promix-155/PA01.141.13E.7602_4.png"
        />
      </pattern>
      <pattern
        id="pattern-7"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 42 35"
      >
        <image
          width="42"
          height="35"
          href="../promix-155/PA01.141.13E.7602_5.png"
        />
      </pattern>
      <pattern
        id="pattern-8"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 28 47"
      >
        <image
          width="28"
          height="47"
          href="../promix-155/PA01.141.13E.7602_3.png"
        />
      </pattern>
      <pattern
        id="pattern-9"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 14 16"
      >
        <image
          width="14"
          height="16"
          href="../promix-155/PA01.141.13E.7602_6.png"
        />
      </pattern>
      <pattern
        id="pattern-10"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 14 16"
      >
        <image
          width="14"
          height="16"
          href="../promix-155/PA01.141.13E.7602_9.png"
        />
      </pattern>
      <pattern
        id="pattern-11"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 18 19"
      >
        <image
          width="18"
          height="19"
          href="../promix-155/PA01.141.13E.7602_10.png"
        />
      </pattern>
      <pattern
        id="pattern-12"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 35 23"
      >
        <image
          width="35"
          height="23"
          href="../promix-155/PA01.141.13E.7602_7.png"
        />
      </pattern>
      <pattern
        id="pattern-13"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 125 145"
      >
        <image
          width="125"
          height="145"
          href="../promix-155/PA01.141.13E.7602_18.png"
        />
      </pattern>
      <pattern
        id="pattern-14"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 257 263"
      >
        <image
          width="257"
          height="263"
          href="../promix-155/PA01.141.13E.7602_14.png"
        />
      </pattern>
    </defs>
    <g
      className="vista-g"
      id="Group_79"
      data-name="Group 79"
      transform="translate(-327 -238)"
    >
      <rect
        id="PA01.141.13E.7602_12"
        width="400"
        height="524"
        transform="translate(838 401)"
        fill="url(#pattern)"
      />
      <rect
        id="PA01.141.13E.7602_15"
        width="78"
        height="92"
        transform="translate(1288 782)"
        fill="url(#pattern-2)"
        data-url="https://shopirbal.com/pt/produto/roda-macica-250-com-freio/"
        data-name="Roda Maciça Ø250 com Freio"
      />
      <rect
        id="PA01.141.13E.7602_13"
        width="43"
        height="29"
        transform="translate(1305 620)"
        fill="url(#pattern-3)"
      />
      <rect
        id="PA01.141.13E.7602_11"
        width="169"
        height="214"
        transform="translate(495 704)"
        fill="url(#pattern-4)"
      />
      <image
        id="PA01.141.13E.7602_1"
        width="223"
        height="272"
        transform="translate(327 344)"
        href="../promix-155/PA01.141.13E.7602_1.png"
        data-url="https://shopirbal.com/pt/produto/cremalheira-b1-laranja-125-140/"
        data-name="Cremalheira de Ferro Fundido 130-150-155"
      />
      <rect
        id="PA01.141.13E.7602_2"
        width="281"
        height="259"
        transform="translate(580 238)"
        fill="url(#pattern-5)"
      />
      <rect
        id="PA01.141.13E.7602_4"
        width="149"
        height="190"
        transform="translate(1351 251)"
        fill="url(#pattern-6)"
      />
      <rect
        id="PA01.141.13E.7602_5"
        width="76"
        height="63"
        transform="translate(1237 351)"
        fill="url(#pattern-7)"
      />
      <rect
        id="PA01.141.13E.7602_3"
        width="51"
        height="85"
        transform="translate(1198 245)"
        fill="url(#pattern-8)"
        data-url="https://shopirbal.com/pt/produto/correia-6pj-636-elastica/"
        data-name="Correia 6PJ 636 Elástica"
      />
      <rect
        id="PA01.141.13E.7602_6"
        width="25"
        height="29"
        transform="translate(995 560)"
        fill="url(#pattern-9)"
        data-url="https://shopirbal.com/pt/produto/pinhao-carreto-b1-150-cavilha/"
        data-name="Pinhão/Carreto B1-150 + CAVILHA"
      />
      <rect
        id="PA01.141.13E.7602_9"
        width="25"
        height="29"
        transform="translate(935 433)"
        fill="url(#pattern-10)"
        data-url="https://shopirbal.com/pt/produto/interruptor-b1/"
        data-name="Interruptor B1"
      />
      <rect
        id="PA01.141.13E.7602_10"
        width="33"
        height="34"
        transform="translate(688 670)"
        fill="url(#pattern-11)"
      />
      <rect
        id="PA01.141.13E.7602_7"
        width="63"
        height="42"
        transform="translate(760 603)"
        fill="url(#pattern-12)"
        data-url="https://shopirbal.com/pt/produto/rolamento-6006-2rs/"
        data-name="Rolamento do Cubo do Balde 6006 2RS"
      />
      <image
        id="PA01.141.13E.7602_8"
        width="44"
        height="33"
        transform="translate(1157 377)"
        href="../promix-155/PA01.141.13E.7602_8.png"
        data-url="https://shopirbal.com/pt/produto/rolamento-6003-2rs/"
        data-name="Rolamento do Lado do Pinhão RL 6003 2RS"
      />
      <rect
        id="PA01.141.13E.7602_18"
        width="46"
        height="53"
        transform="translate(1415 550)"
        fill="url(#pattern-13)"
        data-url="https://shopirbal.com/pt/produto/condensador-700wt/"
        data-name="Condensador 700W"
      />
      <image
        id="PA01.141.13E.7602_17"
        width="72"
        height="84"
        transform="translate(1601 561)"
        href="../promix-155/PA01.141.13E.7602_17.png"
        data-url="https://shopirbal.com/pt/produto/ventoinha-para-motor-700w/"
        data-name="Ventoinha para Motor 700W"
      />
      <rect
        id="PA01.141.13E.7602_14"
        width="95"
        height="96"
        transform="translate(1453 649)"
        fill="url(#pattern-14)"
        data-url="https://shopirbal.com/pt/produto/motor-ele-700w/"
        data-name="Motor ELE 700W"
      />
    </g>
  </svg>
)
